
import { defineComponent } from 'vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import TmTableActionButton from '@/components/shared/table/TmTableActionButton.vue'
import useContactsOrganizationsOpenModal from '@/compositions/contacts/useContactsOrganizationsOpenModal'

export default defineComponent({
  components: {
    TmTableActionButton,
    TmDropdown,
    TmDropdownItem,
  },
  props: {
    row: {
      type: Object,
    },
  },
  setup() {
    const { openEditOrganizationsModal, openDeleteOrganizationModal } = useContactsOrganizationsOpenModal()

    return {
      openEditOrganizationsModal,
      openDeleteOrganizationModal,
    }
  },
})
