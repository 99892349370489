
import type { PropType } from 'vue'
import { defineComponent, ref } from 'vue'
import TopFilter from '@/components/layout/topFilter/TopFilter.vue'
import TopFilterButton from '@/components/layout/topFilter/TopFilterButton.vue'
import TmDropdownDownload from '@/components/shared/dropdowns/TmDropdownDownload.vue'
import DropdownFilterAssignee from '@/components/shared/filters/dropdown/DropdownFilterAssignee.vue'
import DropdownFilterRadio from '@/components/shared/filters/radio/DropdownFilterRadio.vue'
import DropdownFilterStatuses from '@/components/shared/filters/dropdown/DropdownFilterStatuses.vue'
import FilterAdd from '@/components/shared/filters/FilterAdd.vue'

import useContactsOrganizationsOpenModal from '@/compositions/contacts/useContactsOrganizationsOpenModal'
import { useModals } from '@/compositions/modals'
import {
  tagsField, countryField, organizationTypeField, ogranizationOwnerField
} from '@/definitions/_general/_data/bulkFields'
import type { FiltersListType } from '@/definitions/shared/filters/types'

export default defineComponent({
  components: {
    TopFilterButton,
    TopFilter,
    TmDropdownDownload,
    DropdownFilterAssignee,
    DropdownFilterRadio,
    DropdownFilterStatuses,
    FilterAdd,
  },
  props: {
    selected: {
      type: Array,
      required: true,
    },
    filters: {
      type: Object as PropType<FiltersListType>,
    },
  },
  emits: ['update:radio-value', 'update:model-value', 'reset'],
  setup(props) {
    const topFilterRef = ref()
    const search = ref('')
    const { openModal } = useModals()
    const { openOrganizationsModal, openEditOrganizationsModal, openDeleteOrganizationsModal } = useContactsOrganizationsOpenModal()

    const fieldsList = ref([
      organizationTypeField,
      ogranizationOwnerField,
      countryField,
      tagsField,
    ])
    const clickEdit = () => {
      if (props.selected && props.selected.length > 1) {
        openModal('bulkEdit', {
          fields: fieldsList,
          selected: props.selected,
          itemName: 'organization',
        })
      } else {
        openEditOrganizationsModal(props.selected[0])
      }
    }

    const tagsFieldsToEdit = ref([
      tagsField,
    ])
    const openEditTagsModal = () => {
      openModal('bulkEdit', {
        selected: props.selected,
        itemName: 'organization',
        initialFields: tagsFieldsToEdit.value,
        fields: fieldsList.value,
      })
    }
    const openNoteModal = () => {
      openModal('note', {
        modalTitle: 'Add organization note',
        btnText: `Add note ${props.selected.length > 1 ? 'to ' + props.selected.length + ' organizations' : ''}`,
      })
    }

    return {
      topFilterRef,
      search,
      openModal,
      openOrganizationsModal,
      openEditOrganizationsModal,
      openDeleteOrganizationsModal,
      clickEdit,
      openEditTagsModal,
      openNoteModal,
    }
  },
})
