
import { computed, defineComponent, ref } from 'vue'
import PageContent from '@/components/layout/pageContent/PageContent.vue'
import ContactsOrganizationsFilter from '@/components/pages/contacts/organizations/ContactsOrganizationsFilter.vue'
import ContactsOrganizationsTable from '@/components/pages/contacts/organizations/ContactsOrganizationsTable.vue'
import ContactsOrganizationsEmpty from '@/components/pages/contacts/organizations/ContactsOrganizationsEmpty.vue'
import { getTableData } from '@/services/tableService'
import { formatDate } from '@/services/dateTimeService'
import type { TableHeaders } from '@/definitions/shared/types'
import { useModes } from '@/compositions/modes'
import type { ContactOrganization } from '@/definitions/contacts/organizations/types'
import { organizationsFilters } from '@/definitions/shared/filters/filtersList/data'
import { useFilters } from '@/compositions/filters'
import UseContactsSegmentsOpenModal from '@/compositions/contacts/useContactsSegmentsOpenModal'

export default defineComponent({
  components: {
    ContactsOrganizationsEmpty,
    ContactsOrganizationsTable,
    ContactsOrganizationsFilter,
    PageContent,
  },
  props: {
    title: {
      type: String,
    },
  },
  setup() {
    const { isEmptyMode } = useModes()
    const { openSegmentsModal } = UseContactsSegmentsOpenModal()

    const search = ref('')
    const { filters, isDefault, reset } = useFilters(organizationsFilters)
    const selected = ref<ContactOrganization[]>([])
    const tableHeaders = ref<TableHeaders[]>([
      { text: 'Name', value: 'organization' },
      { text: 'Domain', value: 'domains', width: '16%' },
      { text: 'Contacts', value: 'contacts', width: '14%' },
      { text: 'Primary contact', value: 'primary-contact', width: '18%' },
      { text: 'Country', value: 'country', width: 180 },
      { text: 'Organization type', value: 'type', width: 160 },
      { text: 'Last updated', value: 'lastUpdated', format: (val: string) => formatDate(val), width: '12%', hidden: true },
      { text: 'Date created', value: 'dateCreated', format: (val: string) => formatDate(val), width: '12%', hidden: true },
    ])
    const tableItems = ref<ContactOrganization[]>(getTableData('contactsOrganizations'))
    const filteredTableItems = computed<ContactOrganization[]>(() => {
      return tableItems.value.filter((item) => item.organization.organizationName.toLowerCase().includes(search.value.toLowerCase()))
    })

    return {
      isEmptyMode,
      openSegmentsModal,
      filters,
      isDefault,
      reset,
      search,
      selected,
      tableHeaders,
      tableItems,
      filteredTableItems,
    }
  },
})
