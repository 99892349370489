import { resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dropdown_filter_statuses = _resolveComponent("dropdown-filter-statuses")!
  const _component_dropdown_filter_radio = _resolveComponent("dropdown-filter-radio")!
  const _component_dropdown_filter_assignee = _resolveComponent("dropdown-filter-assignee")!
  const _component_filter_add = _resolveComponent("filter-add")!
  const _component_top_filter_button = _resolveComponent("top-filter-button")!
  const _component_tm_dropdown_download = _resolveComponent("tm-dropdown-download")!
  const _component_top_filter = _resolveComponent("top-filter")!

  return (_openBlock(), _createBlock(_component_top_filter, {
    ref: "topFilterRef",
    "show-filters": "",
    "hide-columns-additional-buttons": "",
    selected: _ctx.selected,
    "visible-buttons": 5,
    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('update:model-value', $event))),
    "onUpdate:radioValue": _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('update:radio-value', $event))),
    onReset: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('reset')))
  }, {
    "filter-line-details": _withCtx(({ updateRadioValue, updateModelValue }) => [
      _createVNode(_component_dropdown_filter_statuses, {
        modelValue: _ctx.filters.organizationTypesFilter.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filters.organizationTypesFilter.value) = $event)),
        options: _ctx.filters.organizationTypesFilter.options,
        name: "Organization type",
        "search-placeholder": "Search organization type",
        removable: false
      }, null, 8, ["modelValue", "options"]),
      _createVNode(_component_dropdown_filter_radio, _mergeProps({
        "model-value": _ctx.filters.tags.value
      }, _ctx.filters.tags, {
        name: "Tags",
        "onUpdate:radioValue": ($event: any) => (updateRadioValue('tags', $event)),
        "onUpdate:modelValue": ($event: any) => (updateModelValue('tags', $event))
      }), null, 16, ["model-value", "onUpdate:radioValue", "onUpdate:modelValue"]),
      _createVNode(_component_dropdown_filter_assignee, {
        modelValue: _ctx.filters.owner.value,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.filters.owner.value) = $event)),
        options: _ctx.filters.owner.options,
        label: "Owner",
        "search-placeholder": "Search user"
      }, null, 8, ["modelValue", "options"]),
      _createVNode(_component_dropdown_filter_radio, _mergeProps({
        "model-value": _ctx.filters.countries.value
      }, _ctx.filters.countries, {
        name: "Country",
        "onUpdate:radioValue": ($event: any) => (updateRadioValue('countries', $event)),
        "onUpdate:modelValue": ($event: any) => (updateModelValue('countries', $event))
      }), null, 16, ["model-value", "onUpdate:radioValue", "onUpdate:modelValue"]),
      _createVNode(_component_dropdown_filter_radio, _mergeProps({
        "model-value": _ctx.filters.lastUpdated.value
      }, _ctx.filters.lastUpdated, {
        name: "Last updated",
        "onUpdate:radioValue": ($event: any) => (updateRadioValue('lastUpdated', $event)),
        "onUpdate:modelValue": ($event: any) => (updateModelValue('lastUpdated', $event))
      }), null, 16, ["model-value", "onUpdate:radioValue", "onUpdate:modelValue"]),
      _createVNode(_component_dropdown_filter_radio, _mergeProps({
        "model-value": _ctx.filters.dateCreated.value
      }, _ctx.filters.dateCreated, {
        name: "Date created",
        "onUpdate:radioValue": ($event: any) => (updateRadioValue('dateCreated', $event)),
        "onUpdate:modelValue": ($event: any) => (updateModelValue('dateCreated', $event))
      }), null, 16, ["model-value", "onUpdate:radioValue", "onUpdate:modelValue"]),
      _createVNode(_component_dropdown_filter_assignee, {
        modelValue: _ctx.filters.createdBy.value,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.filters.createdBy.value) = $event)),
        options: _ctx.filters.createdBy.options,
        label: "Created by",
        "search-placeholder": "Search user"
      }, null, 8, ["modelValue", "options"]),
      _createVNode(_component_filter_add, {
        items: _ctx.filters.addFilters
      }, null, 8, ["items"])
    ]),
    "filter-line-left-default": _withCtx(() => [
      _createVNode(_component_top_filter_button, {
        text: "New organization",
        icon: "add",
        onClick: _ctx.openOrganizationsModal
      }, null, 8, ["onClick"]),
      _createVNode(_component_top_filter_button, {
        text: "Import",
        icon: "upload",
        to: { name: 'base.contacts.importFlow' }
      }, null, 8, ["to"])
    ]),
    "filter-line-left-selected": _withCtx(() => [
      _createVNode(_component_top_filter_button, {
        text: "Send SMS",
        icon: "chat"
      }),
      _createVNode(_component_top_filter_button, {
        text: "Edit",
        icon: "edit",
        onClick: _ctx.clickEdit
      }, null, 8, ["onClick"]),
      _createVNode(_component_top_filter_button, {
        text: "Add note",
        icon: "article",
        onClick: _ctx.openNoteModal
      }, null, 8, ["onClick"]),
      _createVNode(_component_top_filter_button, {
        text: "Add tags",
        icon: "sell",
        onClick: _ctx.openEditTagsModal
      }, null, 8, ["onClick"]),
      _createVNode(_component_tm_dropdown_download),
      _createVNode(_component_top_filter_button, {
        text: "Delete",
        icon: "delete",
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.openDeleteOrganizationsModal(_ctx.selected)))
      })
    ]),
    _: 1
  }, 8, ["selected"]))
}